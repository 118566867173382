import React, { useState, createContext, useContext, ReactNode } from 'react';
import { CONST } from './common/enum/enum';
import { ICluster } from './common/tin-gpt/define-cluster';

const MainContext = createContext();
function MainProvider({ children }) {
    const [typeNews, setTypeNews] = useState(1);
    const [footerHeight, setFooterHeight] = useState(0);
    const [topicContent, setTopicContent] = useState(CONST.HOT_TOPIC_ID);
    const [topiclst, setTopiclst] = useState([]);
    const [clickedCluster, setClickedCluster] = useState();
    const [isHideHeader, setIsHideHeader] = useState(false);
    const [isHideFooter, setIsHideFooter] = useState(false);
    const [clusters, setClusters] = useState([]);
    const [articles, setArticles] = useState([]);
    const [lastest, setLastest] = useState([]);
    const [detailNews, setDetailNews] = useState();
    const [detailNewsHeader, setDetailNewsHeader] = useState();
    const [categorys, setCategorys] = useState([]);
    const [lstNewsByCategory, setLstNewsByCategory] = useState([]);
    const [todayNews, setTodayNews] = useState([]);
    const [lstYoutubeNews, setLstYoutubeNews] = useState([]);
    const [lstTiktokNews, setLstTiktokNews] = useState([]);
    const [lstClusterByCategory, setLstClusterByCategory] = useState([]);
    const [isResponsive, setIsResponsive] = useState(false);
    const [widthWeb, setWidthWeb] = useState(window.innerWidth);
    const [summaryCluster, setSummaryCluster] = useState(null);
    const [opinionCluster, setOpinionCluster] = useState([]);
    const [categoryMainNewsHome, setCategoryMainNewsHome] = useState(null)
    const [opinionNews, setOpinionNews] = useState([]);
    const [lstDifferentNews, setLstDifferentNews] = useState([]);
    const [currentWeather, setCurrentWeather] = useState(null); // Thông tin thời tiết hiện tại
    const [todayWeather, setTodayWeather] = useState(null); // Thông tin thời tiết hôm nay
    const [city, setCity] = useState('Hà Nội'); // Thông tin thời tiết hôm nay
    const [newsForYou, setNewsForYou] = useState([]);
    const [profile, setProfile] = useState(null); // Thông tin người dùng 
    const [lstChatRoles, setLstChatRoles] = useState([]);
    const [isLogin, setIsLogin] = useState(false);
    const [selectChatRole, setSelectChatRole] = useState('');
    const [userChatBox, setUserChatBox] = useState('');
    return (
        <MainContext.Provider value={{
            typeNews,
            footerHeight,
            topicContent,
            topiclst,
            clickedCluster,
            setTypeNews,
            setFooterHeight,
            setTopicContent,
            setTopiclst,
            setClickedCluster,
            isHideHeader,
            setIsHideHeader,
            isHideFooter,
            setIsHideFooter,
            clusters,
            setClusters,
            articles,
            setArticles,
            lastest,
            setLastest,
            detailNews,
            setDetailNews,
            categorys,
            setCategorys,
            lstNewsByCategory,
            setLstNewsByCategory,
            todayNews,
            setTodayNews,
            lstYoutubeNews,
            setLstYoutubeNews,
            lstTiktokNews,
            setLstTiktokNews,
            lstClusterByCategory,
            setLstClusterByCategory,
            isResponsive,
            setIsResponsive,
            widthWeb,
            setWidthWeb,
            summaryCluster,
            setSummaryCluster,
            opinionCluster,
            setOpinionCluster,
            categoryMainNewsHome,
            setCategoryMainNewsHome,
            opinionNews,
            setOpinionNews,
            lstDifferentNews,
            setLstDifferentNews,
            currentWeather,
            setCurrentWeather,
            todayWeather,
            setTodayWeather,
            city,
            setCity,
            newsForYou,
            setNewsForYou,
            isLogin,
            setIsLogin,
            profile,
            setProfile,
            lstChatRoles,
            setLstChatRoles,
            selectChatRole,
            setSelectChatRole,
            userChatBox,
            setUserChatBox,
            detailNewsHeader,
            setDetailNewsHeader
        }}>
            {children}
        </MainContext.Provider>
    );
}
export const MainState = () => {
    return useContext(MainContext);
};
export default MainProvider;
